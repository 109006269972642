const Nav = {
  links:[
    {
      value:"Work",
      path:"/#work",
      anchor:"#work"
    },
    {
      value:"Labs",
      path:"/#labs",
      anchor:"#labs"
    },
    {
      value:"About",
      path:"/about"
    },
    {
      value:"Contact",
      path:"/#",
      isContact: true,
    },    
  ],
  

  CVButton:{
    value:"CV",
    path:"#"
  }
}


export default Nav

