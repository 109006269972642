import React from "react"
import {createUseStyles} from "react-jss"




const useStyles = createUseStyles(theme => ({
  buttonBG:{
    borderRadius:8,
    position:"relative",
    maxWidth:props=>props.maxWidth,
    minWidth:(props)=>{return (props.isFullWidth)?"100%":"0"},
    border:props=> props.isSecondary?"1px solid #f2f2f2":"none",
    margin: 0,
    padding:"12px 24px",
    backgroundColor: props => props.isSecondary?theme.colors.primary.white:theme.colors.primary.purpleDark,
    color: props => props.isSecondary?theme.colors.primary.purpleDark:theme.colors.primary.white,
    transition: theme.states.button.transition,
    boxShadow:"0 0px 0px 0 #fff",

    
    "& > svg":{
      width:18,
      height:18
    },
    "& p":{
      zIndex:100
    },
    
    "&:hover":{
      backgroundColor: props=> props.isSecondary?"#fff":theme.states.button.background.hover,
      transition: theme.states.button.transition,
      boxShadow:"0 3px 8px 0 #eee",
      border:props=> props.isSecondary?"1px solid #fff":"none",
  
    },
    "&:active":{
      backgroundColor: props=> props.isSecondary? "#f2f2f2":theme.states.button.background.press,
      // transition: theme.states.button.transition,
      boxShadow:"0 3px 3px 0 #eee",
      
    }
  },

  [theme.breakpoints.m]:{
    buttonBG:{
      minWidth:p=>p.fullWidthAtEveryBreak === true?"100%":"0 !important"

    }
  },
  [theme.breakpoints.l]:{
    buttonBG:{
      minWidth:p=>p.fullWidthAtEveryBreak === true?"100%":"0 !important"
      

    }

  },


}))




const Button= (props) => {
  const s = useStyles(props)

  return (
      
      <button type = {props.type} onClick= {props.onClick} className = {s.buttonBG}>
        {props.children}
      </button>
    
  )
}

Button.defaultProps = {
  onClick: ()=>console.log("Button Clicked"),
  isFullWidth: false,
  isSecondary: false,
  fullWidthAtEveryBreak: false,
  type:null
}

export default Button 
