import React from "react"
import { useState } from "react"
import { useScroll } from "../common/hooks/useScroll"

import { createUseStyles } from "react-jss"
import navSchema from "../data/NavSchema"
import NavItem from "./NavItem"
import CVButton from "../molecules/CVButton"

import ContactModal from "../common/ContactModal"

import Logo from "../atoms/Logo"
const useStyles = createUseStyles((theme) => ({
  sideArea: {
    display: "flex",
    minWidth: 100,
    width: "10%",
    flex: "1 1 auto",
    marginRight: 24,
    marginLeft: 24,
    alignItems: "center",
  },

  leftSide: {
    justifyContent: "flex-start",
  },

  rightSide: {
    justifyContent: "flex-end",
    flex: "0 0 auto",
  },
  navBarContainer: {
    display: "none",
    width: "100vw",
    position: "fixed",
    top: 0,
    transition: theme.transitions.ms300("boxShadow"),
    backgroundColor: "#ffffff",
    boxShadow: "0 -30px 20px -3px #33333322",
    zIndex: 10,
  },

  navBarFlexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyItems: "flex-end",
  },

  navbarLinkUL: {
    display: "flex",
    transition: theme.transitions.ms100("all"),
    width: "80%",
    maxWidth: 600,
    minWidth: 300,
    height: 64,
    overflow: "hidden",
    justifyContent: "flex-end",
  },

  expandedBackground: {
    transition: theme.transitions.ms300("all"),
    padding: 0,

    boxShadow: "0 2px 20px -3px #33333322",
  },
  expandedUL: {},
  [theme.breakpoints.l]: {
    navBarContainer: {
      display: "block",
    },
  },
}))

let getNavItems = () => {
  let result = []
  let counter = 0
  navSchema.links.forEach((e) => {
    result.push(
      <NavItem
        value={e.value}
        key={counter}
        path={e.path}
        anchor={e.anchor}
        isContact={e.isContact}
      ></NavItem>
    )
    counter += 1
  })
  return result
}

const NavBar = ({ children }) => {
  const s = useStyles()
  let [isExpanded, setExpand] = useState(false)
  let navBGExpanded = () => (isExpanded ? " " + s.expandedBackground : " ")
  let navULExpanded = () => (isExpanded ? " " + s.expandedUL : " ")
  let scrollPosition = useScroll()

  if (scrollPosition.y > 24 && !isExpanded) {
    setExpand(true)
  }

  if (scrollPosition.y <= 24 && isExpanded) {
    setExpand(false)
  }

  return (
    <div className={s.navBarContainer + navBGExpanded()}>
      <ContactModal></ContactModal>

      <div className={s.navBarFlexWrapper}>
        <div className={s.sideArea + " " + s.leftSide}>
          <Logo></Logo>
        </div>

        <ul className={s.navbarLinkUL + navULExpanded()}>{getNavItems()}</ul>

        <div className={s.sideArea + " " + s.rightSide}>
          <CVButton></CVButton>
        </div>
      </div>
    </div>
  )
}

export default NavBar
