import { useState, useEffect } from 'react'
import throttle from "lodash.throttle"

export const useScroll = () => {
  let startingState = ()=>{
    if(typeof window !== undefined){
      return {
        x: (typeof window === undefined)?window.scrollX:null,
        y: (typeof window === undefined)?window.scrollY:null,
        direction: ''
      }
    }
    else{
      return {
        x:"",
        y:"",
        direction:""
      }
    }

  }
  // Set a single object `{ x: ..., y: ..., direction: ... }` once on init
  const [scroll, setScroll] = useState(startingState())

  const listener = e => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll(prev => ({
      x: document.body.getBoundingClientRect().left,
      y: -document.body.getBoundingClientRect().top,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction: prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down'
    }))
  }

  useEffect(() => {
    let throttleWrapper = throttle(listener,200,{leading:true})
    if(typeof window!== undefined){

      window.addEventListener('scroll', throttleWrapper)
    }
    // cleanup function occurs on unmount
    return () => {
      if(typeof window !== undefined){
        window.removeEventListener('scroll', throttleWrapper)

      }
    }
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, [])
  
  
  return scroll
}