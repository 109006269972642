import React from "react"

import Nav from "../components/nav/Nav"

import theme from "../styles/Theme"

import { ThemeProvider } from "react-jss"
import { ChakraProvider } from "@chakra-ui/react"

import { extendTheme } from "@chakra-ui/react"
import { chakraTheme } from "../styles/chakraTheme/chakraTheme"

const ckTheme = extendTheme(chakraTheme)

// THIS IS A SPECIAL COMPONENT.  Thanks to gatbsy-plugin-layout, it automatically wraps ALL pages without re-rendering, and hence allows the navigation animations to complete while the page itself transitions to the next one.

export default function Layout({ children }) {
  return (
    <div style={{ overflow: "hidden" }}>
      <ThemeProvider theme={theme}>
        <ChakraProvider theme={ckTheme}>
          <Nav></Nav>
          {children}
        </ChakraProvider>
      </ThemeProvider>
    </div>
  )
}
