import React from "react"
import {Link} from "gatsby"

import {createUseStyles} from "react-jss"

import navSchema from "../data/NavSchema"
import AnchorLink from "react-anchor-link-smooth-scroll"




const useStyles = createUseStyles(theme => ({
  navItem:{
    fontFamily:["Helvetica Neue","sans-serif"],
    padding: [0, 24],
    display:"flex",
    backgroundColor:'#fff',
    borderBottom:"1px solid #fff",
    fontWeight:200,
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
    boxSizing:"border-box",
    marginTop:0,
    flex: "0 1 auto",
    color:[theme.colors.font.onWhite.navItem, "!important"],

    opacity:0.7,
    transition:theme.states.button.transitionFast,
    "&:hover":{
      // backgroundColor:"#11111102",
      borderBottom:"1px solid " + theme.colors.primary.purpleDark,
      // color:theme.colors.font.onWhite.navItem,
      transition:theme.states.button.transitionFast,
      opacity:1
    },


  }
}))




let NavLink = (value, path, anchor, style)=>{
  let LinkComponent = (props)=>(<Link to = {path}>{props.children}</Link>)
  
  if(typeof window !== 'undefined'){
    if(window.location.pathname === "/" && (anchor === navSchema.links[0].anchor || anchor === navSchema.links[1].anchor)){
      LinkComponent = (props)=>(<AnchorLink offset={80} href = {anchor}>{props.children}</AnchorLink>)
    }

  }

  return (
    

    <LinkComponent>
      <li className = {style.navItem}>
        {value}
      </li>
    </LinkComponent>

  )
}


let onClickContact = ()=>{
  let showContactForm = new Event("showContactForm")
  document.dispatchEvent(showContactForm)
}

let ContactItem = (value,style)=>{
  return (
    <li onClick = {onClickContact} className = {style.navItem}>
      {value}
    </li>
  )

}


const NavItem = ({value, path, anchor, isContact}) => {
  const s = useStyles()

  return (    
    (isContact)?ContactItem(value,s):NavLink(value, path, anchor, s)
  )
}

export default NavItem
