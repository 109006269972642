import React from "react"
import { useState } from "react"
import { createUseStyles } from "react-jss"

import assets from "../common/assetList"
import FormField from "../atoms/FormField"
import Button from "../atoms/Button"
import ReCaptcha from "react-google-recaptcha"

const useStyles = createUseStyles((theme) => ({
  captchaWrapper: {
    display: "inline-flex",
    padding: [2, 0, 1, 2],
    border: (p) => {
      if (p.captchaState === "empty") {
        return "none"
      } else if (p.captchaState === "error") {
        return "2px solid red"
      } else {
        return "none"
      }
    },
    borderRadius: 4,
  },

  successWrapper: {
    display: "flex",
    animation: "$successConfirmation 5s ease-in-out",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    maxHeight: "700px",
    boxSizing: "border-box",
    backgroundColor: "white",
    zIndex: 1000,
  },

  "@keyframes successConfirmation": {
    "0%": {
      opacity: 0,
    },
    "10%": {
      opacity: 1,
    },
    "100%": {
      opacity: 1,
    },
  },

  successCheck: {
    backgroundImage: `url(${assets.footer.checkmarkIcon})`,
    backgroundSize: "contain",
    width: 100,
    height: 100,
  },

  successText: {
    width: "100%",
    textAlign: "center",
    fontWeight: 800,
    marginTop: 24,
    opacity: 0.7,
  },

  scrim: {
    opacity: 1,
    zIndex: 100,
    display: "flex",
    top: 0,
    position: "fixed",
    justifyContent: "center",
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000075",
    overflow: "hidden",
    transition: "opacity 0.3s ease-in-out",
  },

  spacer: {
    width: "100%",
    height: 24,
  },

  formWrapper: {
    position: "relative",
    opacity: 1,
    marginTop: 24,
    bottom: 0,
    display: "block",
    justifySelf: "center",
    zIndex: 101,
    width: "100%",
    height: "100%",
    transition: "transform 0.3s ease-in-out",
    transform: "translateY(0px)",
    animationName: "$modal-in-out",
    animation: "0.3s ease-in-out 1 forward running",

    borderRadius: [24, 24, 0, 0],
    overflow: "hidden",
  },

  form: {},

  field: {
    "& > *": {
      fontSize: ["80%", "!important"],
    },
    "& > div": {
      borderRadius: 0,
      border: "1px solid #eee",
    },
  },

  modalHeader: {
    backgroundColor: "#ffffffca",
    backdropFilter: "blur(10px)",
    padding: [16, 24],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h3": {
      fontSize: 24,
    },
  },

  modalBody: {
    padding: 24,
    backgroundColor: "#fff",
    height: "100%",
  },

  closeIcon: {
    height: 24,
    width: 24,
    borderRadius: 50,
    "&>img": {
      width: "100%",
      height: "100%",
    },
  },

  bodyCopy: {
    fontSize: "0.8em",
    opacity: 0.6,
  },

  sendButton: {
    backgroundColor: theme.colors.primary.purpleDark,
    border: "none",
    color: theme.colors.primary.white,
    padding: [16, 24],
    // marginTop:24,
    marginBottom: 24,
    width: "100%",
  },

  [theme.breakpoints.m]: {
    field: {
      "& > *": {
        fontSize: ["100%", "!important"],
      },
    },

    closeIcon: {
      width: 36,
      height: 36,
    },

    formWrapper: {
      maxWidth: 600,
      display: "table",
      borderRadius: 24,
      height: "50%",
    },
    modalHeader: {
      padding: [16, "3vw"],
      paddingRight: "2vw",
      "& > h3": {
        fontSize: "200%",
      },
    },
    modalBody: {
      padding: [24, "3vw"],
    },
  },

  hideScrim: {
    opacity: 0,
  },

  hideModal: {
    transform: "translateY(800px)",
  },

  "@keyframes modal-in-out": {
    "0%": {
      transform: "translateY(800px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
}))

const ContactModal = (props) => {
  const captchaRef = React.createRef()
  let [captchaState, setCaptchaState] = useState({ captchaState: "empty" })
  const s = useStyles({ ...props, ...captchaState })
  let [isShowing, setShowing] = useState(false)
  let [isMounted, setMounted] = useState(false)
  let [formContent, setFormContent] = useState({
    from: "",
    name: "",
    subject: "",
    message: "",
    "form-name": "DB-Design Contact",
    "g-recaptcha-response": "",
  })
  let [sentState, setSentState] = useState({ sent: false })

  let setBodyScrollable = (bool) => {
    let selector = "body, html"
    let elements = document.querySelectorAll(selector)
    if (bool) {
      setOverflowForElements(elements, "auto")
    } else {
      setOverflowForElements(elements, "hidden")
    }
  }

  //set overflow... TODO: this wont work on iOS.
  let setOverflowForElements = (elements, overflowValue) => {
    elements.forEach((e) => {
      e.style.overflow = overflowValue
    })
  }

  let show = () => {
    console.log("show")
    setShowing(true)
    setMounted(true)
    setBodyScrollable(false)
  }

  let hide = () => {
    setShowing(false)
    setTimeout(() => {
      setMounted(false)
    }, 400)
    setBodyScrollable(true)
  }

  let onChangeEmail = (e) => {
    let newContent = { ...formContent }
    newContent.from = escape(e.target.value)
    setFormContent(newContent)
  }

  let onChangeName = (e) => {
    let newContent = { ...formContent }
    newContent.name = escape(e.target.value)
    setFormContent(newContent)
  }

  let onChangeSubject = (e) => {
    let newContent = { ...formContent }
    newContent.subject = escape(e.target.value)
    setFormContent(newContent)
  }

  let onChangeMessage = (e) => {
    let newContent = { ...formContent }
    newContent.message = escape(e.target.value)
    setFormContent(newContent)
  }

  let onSuccessfulCaptcha = (token) => {
    let newContent = { ...formContent }
    newContent["g-recaptcha-response"] = escape(token)
    setFormContent(newContent)
    setCaptchaState({ captchaState: "ok" })
  }

  let onCaptchaExpired = () => {
    let newContent = { ...formContent }
    newContent["g-recaptcha-response"] = ""
    setFormContent(newContent)
    setCaptchaState({ captchaState: "empty" })
  }

  let resetFormValues = () => {
    let fields = document.querySelectorAll("input, textarea")
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = ""
    }
  }

  //TODO: called when form is submitted
  let onFormSend = (e) => {
    e.preventDefault()
    captchaRef.current.reset()

    if (formContent["g-recaptcha-response"] !== "") {
      fetch("/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formContent).toString(),
      })
        .then((success) => {
          setSentState({ sent: true })
          setFormContent({
            from: "",
            name: "",
            subject: "",
            message: "",
            "g-recaptcha-response": "",
          })
          setTimeout(() => {
            hide()
            resetFormValues()
            setTimeout(() => {
              setSentState({ sent: false })
            }, 500)
          }, 1000)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      setCaptchaState({ captchaState: "error" })
    }

    // hide()

    return false
  }

  if (typeof document !== "undefined") {
    document.addEventListener("showContactForm", (e) => {
      show()
    })
  }

  let hideScrimClass = isShowing ? "" : " " + s.hideScrim
  let hideModalClass = isShowing ? "" : " " + s.hideModal

  return (
    <div
      style={{
        display: isMounted ? "flex" : "none",
      }}
      onClick={hide}
      className={s.scrim + hideScrimClass}
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
        aria-label=""
        className={s.formWrapper + hideModalClass}
      >
        <div
          style={{ display: sentState.sent ? "flex" : "none" }}
          className={s.successWrapper}
        >
          <i className={s.successCheck}></i>
          <p className={s.successText}>
            Message sent!{" "}
            <span role="img" aria-label="Double high-five emoji.">
              🙌
            </span>{" "}
          </p>
        </div>

        <div className={s.modalHeader}>
          <h3>Contact me</h3>

          <button
            onClick={hide}
            aria-label="close contact modal"
            className={s.closeIcon}
          >
            <img src={assets.icons.nav.contactClose} alt="Close modal"></img>
          </button>
        </div>

        <div className={s.modalBody}>
          <p className={s.bodyCopy}>
            Questions, projects, random hellos? Get in touch!
          </p>

          <form
            data-netlify="true"
            data-netlify-recaptcha="true"
            name="DB-Design Contact"
            className={s.form}
            validate="true"
            autoComplete="off"
            method="post"
            onSubmit={onFormSend}
          >
            <input type="hidden" name="form-name" value="DB-Design Contact" />
            <div className={s.spacer}></div>
            <FormField
              name="from"
              keyboardType="email-address"
              required
              type="email"
              placeholder="Your email"
              onChange={onChangeEmail}
            ></FormField>
            <FormField
              name="name"
              type="text"
              placeholder="Your Name"
              onChange={onChangeName}
            ></FormField>
            <FormField
              name="subject"
              type="text"
              placeholder="Subject (optional)"
              onChange={onChangeSubject}
            ></FormField>
            <FormField
              name="message"
              required
              type="text"
              placeholder="Message"
              multiline
              onChange={onChangeMessage}
            ></FormField>
            {/* <div className="g-recaptcha" data-callback = "capcha" data-sitekey="6Ldic_kSAAAAAGuD_iRT1n3g_Dtogm_NKocNcwHQ"></div> */}
            <div className={s.captchaWrapper}>
              <ReCaptcha
                ref={captchaRef}
                sitekey="6LfSrQsTAAAAAAr87I9b18uO1L_jHrrH9yD63GWa"
                onChange={onSuccessfulCaptcha}
                onExpired={onCaptchaExpired}
              ></ReCaptcha>
            </div>
            <div className={s.spacer}></div>

            {/* <input className = {s.sendButton} type = "submit" value="Send" /> */}
            <Button type="submit" isFullWidth={true} fullWidthAtEveryBreak>
              Send
            </Button>
            <div className={s.spacer}></div>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactModal.defaultProps = {}

export default ContactModal
