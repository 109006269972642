import React from "react"
import { createUseStyles } from "react-jss"

import DLIconURL from "../../assets/icon/download.svg"

// import {createUseStyles} from "react-jss"
const useStyles = createUseStyles((theme) => ({
  CVButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: [16, 24],
    fontWeight: 700,
    transition: theme.transitions.ms300("all"),
    opacity: 0.9,
    transform: "translateY(0px)",
    backgroundColor: "#fff",
    color: theme.colors.primary.purpleDark,
    "&:hover": {
      transform: "translateY(1px)",
      opacity: 1,
      // backgroundColor:"#fafafa"
    },
  },

  icon: {
    width: 16,
    height: 16,
    marginBottom: -3,
    marginRight: 8,
  },
}))

let onClickCVDownload = () => {}

const CVButton = ({ callback }) => {
  const s = useStyles()

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://dmitribilyk.s3-us-west-1.amazonaws.com/Dmitri+Bilyk+Resume+2023.pdf"
    >
      <button
        className={s.CVButtonContainer}
        style={{ minWidth: 120 }}
        onClick={onClickCVDownload}
      >
        <img className={s.icon} src={DLIconURL} alt={"download icon"} />
        CV
      </button>
    </a>
  )
}

export default CVButton
