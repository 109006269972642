import React from "react"
import {useState} from "react"

import {createUseStyles} from "react-jss"


const useStyles = createUseStyles(theme => ({
  navItemWrapper:{
    width:"100%",
    zIndex:10000,
    display:"flex",
    height:72,
    alignItems:"center",
    color:theme.colors.primary.black,
    
  },
  activeIndicator:{
    // opacity:0,
    display:"block",
    borderRadius:"0 40px 40px 0",
    width:4,
    height:"calc(100% - 8px)",
    margin:[4,0],
    backgroundColor:theme.colors.primary.purpleDark,
    transition:theme.transitions.ms100("opacity, width"),

  },

  text:{
    textShadow:"0 3px 20px #00000044",
    color:theme.colors.font.onWhite.navItem,
    marginLeft:48,
    fontWeight:500,
    transition:theme.transitions.ms100("opacity"),
  }
}))



let onClickNavItem = (setState, callback)=>{
  setState(true)
  callback()
  setTimeout(()=>{
    setState(false)
  },1000)
}




const NavMobileItem = (props) => {
  const s = useStyles()

  let [isActive,setActive] = useState(false)

  let textOpacity = (isActive)?1:0.7
  let indicatorOpacity = (isActive)?1:0

  

  return (
    <div 
      onClick = {()=>onClickNavItem(setActive, props.callback)}  
      className = {s.navItemWrapper}
    >
      <span 
        style = {
          {
            opacity:indicatorOpacity,
            width:`${indicatorOpacity*4}px`
            
          }
        }

        className = {s.activeIndicator}
      ></span>
      {/* <Link to={props.path}> */}
        <h4 
          style = {
            {
              opacity:textOpacity,
              
            }
          }
          
          className = {s.text}
          >
          {props.children}
        </h4>
      {/* </Link> */}




    </div>
    
  )
}

NavMobileItem.defaultProps = {
  // value:"NO VALUE",
  // isContact:false,
  // path:"/",
  // anchor: "#",
  callback:()=>{console.log("clicked a nav item.")}

}


export default NavMobileItem
