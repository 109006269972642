import { Theme, defineStyleConfig } from "@chakra-ui/react"

export const chakraTheme = {
  fonts: {
    body: "Raleway, sans serif",
    heading: "Raleway, sans serif",
    mono: "Raleway, sans serif",
  },
  components: {
    Button: defineStyleConfig({
      baseStyle: {
        bg: "#985AE0",
        borderRadius: 8,
        _hover: {
          backgroundColor: "#985AE0",
        },
      },
    }),
  },

  breakpoints: {
    base: "0",
    sm: "320px",
    md: "768px",
    lg: "1025px",
    xl: "1200px",
    "2xl": "1400px",
  },
}
