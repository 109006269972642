//this is where you define the global theme for the site.
const Theme = {
  colors: {
    primary: {
      blue: "#6870EE",
      pink: "#F66294",
      purpleDark: "#454AEA",
      purpleLight: "#985AE0",
      white: "#FFFFFF",
      black: "#333333",
    },

    secondary: {
      blue: "#C6D7FF",
      pink: "#F8D6F0",
      purple: "#D5C8E6",
    },
    font: {
      onWhite: {
        navItem: "#000",
        CTA: "#FFFFFF",
        responsibilityTags: "#131415aa",
        heading: "#131415EE",
        standFirst: "131415DD",
        subheading: "#131415DD",
        paragraph: "#131415EE",
        link: "#1857e1",
        bullet: "#131415BB",
        caption: "#131415BB",
        overline: "#131415BB",
      },
      onBlack: {
        CTA: "#4A3766",
        metaTags: "#ffffff99",
        heading: "#FFFFFFDD",
        body: "#FFFFFFCC",
        link: "#4580ff",
        caption: "#FFFFFFBB",
        overline: "#FFFFFFBB",
      },
    },
  },

  //these are also referenced in styles>global.css
  breakpoints: {
    xs: "@media (min-width: 320px)",
    m: "@media (min-width: 768px)",
    l: "@media (min-width: 1025px)",
    xl: "@media (min-width: 1200px)",
  },

  projectHeroBackgrounds: {
    uxSite: "linear-gradient(135deg, #FFB7B7 0%, #6E34C4 100%);",
  },

  transitions: {
    ms50: (items) => items + " 0.05s ease-in-out",
    ms100: (items) => items + " 0.1s ease-in-out",
    ms200: (items) => items + " 0.2s ease-in-out",
    ms300: (items) => items + " 0.3s ease-in-out",
    ms500: (items) => items + " 0.5s ease-in-out",
    ms800: (items) => items + " 0.8s ease-in-out",
    ms1000: (items) => items + " 1s ease-in-out",
    ms2000: (items) => items + " 2s ease-in-out",
  },

  animations: {
    homeHover: {
      timingFunction: "cubic-bezier(.33,.04,.53,1.00)",
      keyframes: {
        "0%": {
          transform: "translate3D(0px, 0px, 0px) skewX(0deg)",
        },

        "50%": {
          transform: "translate3D(0px, -20px, 0px) skewX(1deg)",
        },

        "100%": {
          transform: "translate3D(0px, 0px, 0px) skewX(0deg)",
        },
      },
    },
  },

  states: {
    button: {
      gradient: "linear-gradient(227deg, #9B5DDF 0%, #854BEE 100%)",
      transition: "all 0.2s ease-in-out",
      transitionFast: "all 0.08s linear",
      background: {
        hover: "#444CB9",
        press: "#343A94",
      },
    },
    card: {},
  },
}

export default Theme
