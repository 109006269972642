exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alcon-vision-js": () => import("./../../../src/pages/alconVision.js" /* webpackChunkName: "component---src-pages-alcon-vision-js" */),
  "component---src-pages-dtv-guide-js": () => import("./../../../src/pages/dtvGuide.js" /* webpackChunkName: "component---src-pages-dtv-guide-js" */),
  "component---src-pages-go-mrkt-js": () => import("./../../../src/pages/goMrkt.js" /* webpackChunkName: "component---src-pages-go-mrkt-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-list-editor-js": () => import("./../../../src/pages/ItListEditor.js" /* webpackChunkName: "component---src-pages-it-list-editor-js" */),
  "component---src-pages-making-of-js": () => import("./../../../src/pages/makingOf.js" /* webpackChunkName: "component---src-pages-making-of-js" */),
  "component---src-pages-mission-control-js": () => import("./../../../src/pages/MissionControl.js" /* webpackChunkName: "component---src-pages-mission-control-js" */),
  "component---src-pages-origami-server-js": () => import("./../../../src/pages/origamiServer.js" /* webpackChunkName: "component---src-pages-origami-server-js" */),
  "component---src-pages-people-fund-js": () => import("./../../../src/pages/peopleFund.js" /* webpackChunkName: "component---src-pages-people-fund-js" */),
  "component---src-pages-smart-room-js": () => import("./../../../src/pages/SmartRoom.js" /* webpackChunkName: "component---src-pages-smart-room-js" */),
  "component---src-pages-ui-explorations-js": () => import("./../../../src/pages/UIExplorations.js" /* webpackChunkName: "component---src-pages-ui-explorations-js" */)
}

