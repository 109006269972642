import React from "react"
import {createUseStyles} from "react-jss"

import assets from "../common/assetList"


const useStyles = createUseStyles(theme => ({
  CLASSNAME:{
    
    minWidth:(props)=>props.width,
    minHeight:props => props.height,
    height:props => props.height,
    widht:props => props.width,
  },
  link:{
    display:"flex",
  }
}))



const Logo = ({children, ...props}) => {
  const s = useStyles(props)
  
  return (
    <a href = "https://db-design.co" className = {s.link}>
      <img src = {assets.logo} alt = "DB-Design Logo" className = {s.CLASSNAME}></img>

    </a>
    )
  }
  
  Logo.defaultProps = {
    width:40,
    height:40
  }
export default Logo
