import React from "react"
import { createUseStyles } from "react-jss"
import { useState } from "react"
import { useScroll } from "../common/hooks/useScroll"

import assets from "../common/assetList"

import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"

import ContactModal from "../common/ContactModal"

import navSchema from "../data/NavSchema"
// import MobileNavBackground from "../common/MobileNavBackground"
import NavMobileItem from "./NavMobileItem"
import Logo from "../atoms/Logo"

const useStyles = createUseStyles((theme) => ({
  mobileMenuWrapper: {
    position: "relative",
    zIndex: 1000,
  },
  scrim: {
    position: "fixed",
    willChange: "transform",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#00000022",
    transitionProperty: "opacity, transform",
    transitionDuration: "200ms, 0ms",
    transitionDelay: "0ms, 0ms",
    transform: "translate(0px, 0px)",
    opacity: 1,
  },

  hideScrim: {
    transitionDelay: "0ms, 200ms",
    transform: "translate(10000px, 0px)",
    opacity: 0,
  },

  sideSheetWrapper: {
    width: "calc(100vw - 48px)",
    maxWidth: 300,
    boxShadow: "0 0 40px 0 #14113A44",
    height: "100vh",
    backgroundColor: theme.colors.primary.white + "fa",
    transition: theme.transitions.ms300("right"),
    position: "fixed",
    top: 0,
    right: -380,
  },

  sideSheetInnerWrapper: {
    position: "relative",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  showMenu: {
    right: 0,
  },

  topBarWrapper: {
    zIndex: 10,
    position: "fixed",
    transform: "translate3d(0,0,0)",
    padding: [24, 16, 0, 24],
    boxSizing: "border-box",
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  logoWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    padding: 24,
    zIndex: -100,
    opacity: 1,
    transform: "scale(1)",
    transition: theme.transitions.ms200("all"),
    "& > img": {
      height: 36,
      marginTop: 8,
    },
  },

  toggleNav: {
    position: "relative",
    boxSizing: "border-box",
    outline: "none",
    backgroundColor: theme.colors.primary.white,
    width: 42,
    height: 42,
    padding: 6,
    paddingTop: 7,
    borderRadius: 50,
    boxShadow: "0 4px 13px 0 #0F123511",
    transform: "scale(1)",
    transition: theme.transitions.ms200(
      "transform, background-color, box-shadow"
    ),
    "&> img": {
      transition: theme.transitions.ms200("transform, opacity"),
    },

    right: 0,
    "&:active": {
      transform: "scale(1.1)",
      backgroundColor: "#fafafa",
    },
  },

  menuIcon: {
    transition: theme.transitions.ms200("transform, opacity"),
    opacity: 1,
    width: "100%",
    height: "100%",
  },

  closeMenuIcon: {
    transition: theme.transitions.ms200("transform, opacity"),
    opacity: 0,
    position: "absolute",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    padding: 6,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  [theme.breakpoints.m]: {
    topBarWrapper: {
      padding: [36, 36, 0, 48],
    },
    toggleNav: {
      height: 56,
      width: 56,
      padding: 10,
      paddingTop: 12,
    },
    closeMenuIcon: {
      padding: 10,
    },
    logoWrapper: {
      padding: 36,
      "& > img": {
        height: 42,
      },
    },
  },

  [theme.breakpoints.l]: {
    mobileMenuWrapper: {
      display: "none",
    },
  },

  "@keyframes scrim-in-out": {
    "0%": {
      display: "none",
    },
    "100%": {
      display: "block",
    },
  },
}))

let onToggleMenu = (lastState, setState) => {
  setState(!lastState)
}

let onClickScrim = (setState) => {
  setState(false)
}

//just close the menu, but give time for animations.
let onClickNavItem = (setState) => {
  setTimeout(() => setState(false), 400)
}

//if the nav item is Contact, we do a different thing.
let onClickContact = (setState) => {
  onClickNavItem(setState)
  let showContactForm = new Event("showContactForm")
  setTimeout(() => document.dispatchEvent(showContactForm), 300)
}

let getNavItems = (setShowMenuState) => {
  return navSchema.links.map((e, i) => {
    let LinkComponent = (props) => <Link to={e.path}>{props.children}</Link>
    let callbackAction = onClickNavItem

    if (typeof window !== "undefined") {
      //if were on the home page, and the user clicks work or labs, then we use the scrolling link and not the one that goes to a different page.
      if (window.location.pathname === "/" && (i === 0 || i === 1)) {
        LinkComponent = (props) => (
          <AnchorLink offset={80} href={e.anchor}>
            {props.children}
          </AnchorLink>
        )
      }
    }

    if (e.isContact) {
      callbackAction = onClickContact
      LinkComponent = (props) => <div>{props.children}</div>
    }

    return (
      <LinkComponent key={`link-${i}`}>
        <NavMobileItem
          path={e.path}
          anchor={e.anchor}
          callback={() => callbackAction(setShowMenuState)}
        >
          {e.value}
        </NavMobileItem>
      </LinkComponent>
    )
  })
}

const NavMobileMenu = (props) => {
  const s = useStyles(props)

  let scrollY = useScroll().y

  let [showMenu, setShowMenu] = useState(false)
  let sideMenuStyles = s.sideSheetWrapper + (showMenu ? " " + s.showMenu : "")
  let logoShowing = scrollY < 1 ? 1 : 0
  let menuIconShowing = showMenu ? 0 : 1
  let closeMenuIconShowing = showMenu ? 1 : 0

  //TODO: SCrim is blocking click events even when not shown.  Need to change this to display:none or hidden.
  let scrimOpacity = showMenu ? 1 : 0
  let scrimClasses = !showMenu ? `${s.hideScrim} ${s.scrim}` : s.scrim

  let toggleStylesWhenMenuIsShowing = {
    backgroundColor: "#515be711",
    boxShadow: "0 0 0 0 #fff",
  }

  let currentToggleStyles = showMenu ? toggleStylesWhenMenuIsShowing : {}

  return (
    <div className={s.mobileMenuWrapper}>
      {/* this listens for any time the contact nav item is triggered. */}
      <ContactModal></ContactModal>

      {/* background scrim */}
      <div
        onClick={() => onClickScrim(setShowMenu)}
        className={scrimClasses}
        style={{ opacity: scrimOpacity }}
      ></div>

      <div
        style={{
          opacity: logoShowing,
          transform: `scale(${logoShowing / 10 + 0.9}) translate(0, ${-(
            64 -
            logoShowing * 64
          )}px)`,
        }}
        className={s.logoWrapper}
      >
        <Logo></Logo>
      </div>
      <div className={s.topBarWrapper}>
        <div></div>

        <button
          style={currentToggleStyles}
          onClick={() => onToggleMenu(showMenu, setShowMenu)}
          className={s.toggleNav}
        >
          <img
            style={{
              opacity: menuIconShowing,
              transform: `scale(${menuIconShowing})`,
            }}
            className={s.menuIcon}
            src={assets.icons.nav.menu}
            alt="menu icon."
          ></img>

          <img
            style={{
              opacity: closeMenuIconShowing,
              transform: `scale(${closeMenuIconShowing})`,
            }}
            className={s.closeMenuIcon}
            src={assets.icons.nav.closeMenu}
            alt="close menu icon."
          ></img>
        </button>
      </div>

      <div className={sideMenuStyles}>
        {/* this needs relative position to make sure the bg asset displays correctly without interrupting the flow. */}
        <div className={s.sideSheetInnerWrapper}>
          {/* <MobileNavBackground showColor = {showMenu}></MobileNavBackground> */}

          {getNavItems(setShowMenu)}
        </div>
      </div>
    </div>
  )
}

NavMobileMenu.defaultProps = {}

export default NavMobileMenu
