import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme) => ({
  container: {
    "& > textarea, input": {
      innerShadow: "none",
      width: "100%",
      border: "1px solid #22222222",
      borderRadius: 8,
      padding: 8,
      boxSizing: "border-box",
      marginBottom: 16,
      fontSize: "0.8em",
      fontFamily: "Quicksand, Helvetica Neue, Arial",
    },
    "& > textarea": {
      resize: "none",
      minHeight: "12vh",
      maxHeight: "12vh",
    },
    "& > input": {},
  },
}))

const FormField = (props) => {
  const s = useStyles(props)

  return (
    <div className={s.container}>
      {props.multiline ? (
        <textarea
          name={props.name}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
          keyboardtype={props.keyboardType}
        >
          {props.children}
        </textarea>
      ) : (
        <input
          name={props.name}
          autoComplete={props.autoComplete}
          keyboardtype={props.keyboardType}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type}
          onChange={props.onChange}
        >
          {props.children}
        </input>
      )}
    </div>
  )
}

FormField.defaultProps = {
  name: null,
  type: "text",
  pattern: null,
  placeholder: "",
  multiline: false,
  required: false,
  onChange: null,
  autoComplete: null,
  keyboardType: null,
}

export default FormField
