import React from "react"
import {createUseStyles} from "react-jss"

import NavBar from "../nav/NavBar"
import NavMobile from "../nav/NavMobileMenu"



const useStyles = createUseStyles(theme => ({
  container:{

  },
  
}))




const Nav = (props) => {
  const s = useStyles(props)

  return (
    <div
      className= {s.container}
    >
      
      <NavBar></NavBar>
      <NavMobile></NavMobile>

    </div>  


  )
}

Nav.defaultProps = {
  
}

export default Nav
