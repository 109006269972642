//tools used
import drawio from "../../assets/icon/tools/drawio.png"
import dynalist from "../../assets/icon/tools/dynalist.png"
import dynamodb from "../../assets/icon/tools/dynamodb.png"
import git from "../../assets/icon/tools/git.png"
import invision from "../../assets/icon/tools/invision.png"
import lucidchart from "../../assets/icon/tools/lucidchart.png"
import nodejs from "../../assets/icon/tools/nodejs.png"
import origami from "../../assets/icon/tools/origami.png"
import principle from "../../assets/icon/tools/principle.png"
import react from "../../assets/icon/tools/react.png"
import sketch from "../../assets/icon/tools/sketch.png"
import teamgantt from "../../assets/icon/tools/teamgantt.png"
import airtable from "../../assets/icon/tools/airtable.png"

import vscode from "../../assets/icon/tools/vscode.png"

import sheets from "../../assets/icon/tools/sheets.png"
import mural from "../../assets/icon/tools/mural.png"
import cc from "../../assets/icon/tools/cc.png"
import js from "../../assets/icon/tools/js.png"
import socketio from "../../assets/icon/tools/socketio.png"
import css from "../../assets/icon/tools/css.png"
import html from "../../assets/icon/tools/html.png"
import unity from "../../assets/icon/tools/unity.png"
import confluence from "../../assets/icon/tools/confluence.png"
import jira from "../../assets/icon/tools/jira.png"
import slack from "../../assets/icon/tools/slack.png"
import notion from "../../assets/icon/tools/notion.png"

import segment from "../../assets/icon/tools/segment.png"
import figma from "../../assets/icon/tools/figma.png"
import framer from "../../assets/icon/tools/framer.png"
import logrocket from "../../assets/icon/tools/logrocket.png"
import mixpanel from "../../assets/icon/tools/mixpanel.png"

//Social icons
import github from "../../assets/icon/social/github.png"
import instagram from "../../assets/icon/social/instagram.png"
import medium from "../../assets/icon/social/medium.png"
import linkedin from "../../assets/icon/social/linkedin.png"
import dribbble from "../../assets/icon/social/dribbble.png"

//Nav icons
import nextProjectArrow from "../../assets/icon/nav/icon_nextProject.png"
import contactClose from "../../assets/icon/nav/contactClose.svg"
import menu from "../../assets/icon/nav/menu.png"
import closeMenu from "../../assets/icon/nav/closeMenu.png"

//About page
import aboutImage01 from "../../assets/imgV2/about-01.jpg"
import aboutImage02 from "../../assets/imgV2/about-02.jpg"
import aboutImage03 from "../../assets/imgV2/about-03.jpg"
import aboutImage04 from "../../assets/imgV2/about-04.jpg"
import aboutImage05 from "../../assets/imgV2/about-05.jpg"
import aboutImage06 from "../../assets/imgV2/about-06.jpg"

import journeyMaps from "../../assets/imgV2/about-illustration-01.jpg"
import uxSpecs from "../../assets/imgV2/about-illustration-02.jpg"
import researchTesting from "../../assets/imgV2/about-illustration-03.jpg"
import prototyping from "../../assets/imgV2/about-illustration-04.jpg"
import analytics from "../../assets/imgV2/about-illustration-05.jpg"
import accessibility from "../../assets/imgV2/about-illustration-06.jpg"

//logo
import Logo from "../../assets/icon/logo.png"
import openGraph from "../../assets/img/generic/openGraph.jpg"

//footer
// import sayHi from "../../assets/img/footer/SayHi.svg"
import backgroundLogo from "../../assets/img/footer/footerBackground.png"
import copyIcon from "../../assets/icon/footer/copy.svg"
import callIcon from "../../assets/icon/footer/call.svg"
import editIcon from "../../assets/icon/footer/edit.svg"
import checkIcon from "../../assets/icon/footer/checkmark.svg"

//generic
import bgStripes from "../../assets/img/background/bgStripes.svg"
import blockQuote from "../../assets/icon/blockQuote.png"

//Index
import homeBG from "../../assets/img/background/hero_paint_2.jpg"
import homeCircle from "../../assets/img/background/hero_circle_2.svg"

import itlistEditorHomeHero1 from "../../assets/img/pages/home/itlist-editor-home-hero-1.png"
import itlistEditorHomeHero2 from "../../assets/img/pages/home/itlist-editor-home-hero-2.png"

import DTVGHeroPt1 from "../../assets/img/pages/home/dtv-guide-home-hero-1.png"
import DTVGHeroPt2 from "../../assets/img/pages/home/dtv-guide-home-hero-2.png"

import homeSelfie from "../../assets/img/pages/home/about-home-selfie.png"
import aboutBrushStroke from "../../assets/img/pages/home/about-brush-stroke.jpg"

import SmartRoomMockup from "../../assets/img/pages/home/SmartRoom_Phone.png"
import iphoneFaceDown from "../../assets/img/pages/home/iphone-facedown-white.png"

import missionControlHomehero from "../../assets/img/pages/home/MC-home-hero-v2.png"
import missionControlHomehero2 from "../../assets/img/pages/home/MC-home-hero-pt2-v2.png"

import alconCard from "../../assets/img/pages/home/lab-alcon.jpg"
import goMrktCard from "../../assets/img/pages/home/lab-gomrkt.jpg"
import origamiCard from "../../assets/img/pages/home/lab-origami-server.jpg"
import dbDesignCard from "../../assets/img/pages/home/lab-portfolio.jpg"
import dailyUiCard from "../../assets/img/pages/home/lab-daily-ui.jpg"
import peopleFundCard from "../../assets/img/pages/home/lab-peoplefund.jpg"

//Smart Room Project
import smartRoomHeroVideo from "../../assets/video/smartroom/smartRoom-hero.mp4"

import upNextCard from "../../assets/img/generic/upNextCard.jpg"

import ILEVideoHero from "../../assets/video/ItListEditor/itlist-editor-hero-video.mp4"
import ILEVideoPrototype from "../../assets/video/ItListEditor/itlist-editor-prototype-video.mp4"
import ILEVideo01 from "../../assets/video/ItListEditor/editor-ui-01.mp4"
import ILEVideo02 from "../../assets/video/ItListEditor/editor-ui-02.mp4"
import ILEVideo03 from "../../assets/video/ItListEditor/editor-ui-03.mp4"
import ILEVideo04 from "../../assets/video/ItListEditor/editor-ui-04.mp4"
import ILEVideo05 from "../../assets/video/ItListEditor/editor-ui-05.mp4"
import ILEVideo06 from "../../assets/video/ItListEditor/editor-ui-06.mp4"

import ILE01 from "../../assets/img/projects/ItListEditor/il-editor-01.jpg"
import ILE02 from "../../assets/img/projects/ItListEditor/il-editor-02.jpg"
import ILE03 from "../../assets/img/projects/ItListEditor/il-editor-03.jpg"
import ILE04 from "../../assets/img/projects/ItListEditor/il-editor-04.jpg"
import ILE05 from "../../assets/img/projects/ItListEditor/il-editor-05.jpg"
import ILE06 from "../../assets/img/projects/ItListEditor/il-editor-06.jpg"
import ILE07 from "../../assets/img/projects/ItListEditor/il-editor-07.jpg"
import ILE08 from "../../assets/img/projects/ItListEditor/il-editor-08.jpg"
import ILE09 from "../../assets/img/projects/ItListEditor/il-editor-09.jpg"
import ILE10 from "../../assets/img/projects/ItListEditor/il-editor-10.jpg"
import ILE11 from "../../assets/img/projects/ItListEditor/il-editor-11.jpg"
import ILE12 from "../../assets/img/projects/ItListEditor/il-editor-12.jpg"
import ILE13 from "../../assets/img/projects/ItListEditor/il-editor-13.jpg"
import ILE14 from "../../assets/img/projects/ItListEditor/il-editor-14.jpg"
import ILE15 from "../../assets/img/projects/ItListEditor/il-editor-15.jpg"
import ILE16 from "../../assets/img/projects/ItListEditor/il-editor-16.jpg"
import ILE17 from "../../assets/img/projects/ItListEditor/il-editor-17.jpg"
import ILE18 from "../../assets/img/projects/ItListEditor/il-editor-18.jpg"
import ILE19 from "../../assets/img/projects/ItListEditor/il-editor-19.jpg"
import ILE20 from "../../assets/img/projects/ItListEditor/il-editor-20.jpg"
import ILE21 from "../../assets/img/projects/ItListEditor/il-editor-21.jpg"
import ILE22 from "../../assets/img/projects/ItListEditor/il-editor-22.jpg"
import ILE23 from "../../assets/img/projects/ItListEditor/il-editor-23.jpg"
import ILE24 from "../../assets/img/projects/ItListEditor/il-editor-24.jpg"

import DTVGVideoHero from "../../assets/video/dtvGuide/dtv-guide-hero-video.mp4"
import DTVGVideo01 from "../../assets/video/dtvGuide/dtv-guide-early-proto.mp4"
import DTVGVideo02 from "../../assets/video/dtvGuide/dtv-guide-launcher.mp4"
import DTVGVideo03 from "../../assets/video/dtvGuide/dtv-guide-final-proto.mp4"
import DTVGVideo04 from "../../assets/video/dtvGuide/dtv-guide-motion-specs.mp4"

import DTVG01 from "../../assets/img/projects/dtvGuide/dtv-guide-01.jpg"
import DTVG02 from "../../assets/img/projects/dtvGuide/dtv-guide-02.jpg"
import DTVG03 from "../../assets/img/projects/dtvGuide/dtv-guide-03.jpg"
import DTVG04 from "../../assets/img/projects/dtvGuide/dtv-guide-04.jpg"
import DTVG05 from "../../assets/img/projects/dtvGuide/dtv-guide-05.jpg"
import DTVG06 from "../../assets/img/projects/dtvGuide/dtv-guide-06.jpg"
import DTVG07 from "../../assets/img/projects/dtvGuide/dtv-guide-07.jpg"
import DTVG08 from "../../assets/img/projects/dtvGuide/dtv-guide-08.jpg"
import DTVG09 from "../../assets/img/projects/dtvGuide/dtv-guide-09.jpg"
import DTVG10 from "../../assets/img/projects/dtvGuide/dtv-guide-10.jpg"
import DTVG11 from "../../assets/img/projects/dtvGuide/dtv-guide-11.jpg"

import SM01 from "../../assets/img/projects/smartroom/smartRoom-logic-fullsize.png"
import SM02 from "../../assets/img/projects/smartroom/smartRoom-logic-issuesA.png"
import SM03 from "../../assets/img/projects/smartroom/smartRoom-logic-issuesB.png"
import SM04 from "../../assets/img/projects/smartroom/smartRoom-logic-issuesC.png"
import SM05 from "../../assets/img/projects/smartroom/smartRoom-logic-issuesD.png"
import SM06 from "../../assets/img/projects/smartroom/smartRoom-problems-hub.png"
import SM07 from "../../assets/img/projects/smartroom/smartRoom-problems-remote.png"
import SM08 from "../../assets/img/projects/smartroom/smartRoom-problems-watchNow.png"

import SM09 from "../../assets/img/projects/smartroom/smartRoom-ideation-crazyEights.png"
import SM10 from "../../assets/img/projects/smartroom/smartRoom-ideation-whiteboarding.png"
import SM11 from "../../assets/img/projects/smartroom/smartRoom-ideation-HMW.png"

import SM12 from "../../assets/img/projects/smartroom/smartRoom-competitors-screens.png"
import SM13 from "../../assets/img/projects/smartroom/smartRoom-ia-exploration.png"

import SM14 from "../../assets/img/projects/smartroom/smartRoom-wires-1.png"
import SM15 from "../../assets/img/projects/smartroom/smartRoom-wires-2.png"
import SM16 from "../../assets/img/projects/smartroom/smartRoom-wires-3.png"
import SM17 from "../../assets/img/projects/smartroom/smartRoom-wires-4.png"
import SM18 from "../../assets/img/projects/smartroom/smartRoom-wires-5.png"
import SM19 from "../../assets/img/projects/smartroom/smartRoom-wires-6.png"
import SM20 from "../../assets/img/projects/smartroom/smartRoom-wires-7.png"
import SM21 from "../../assets/img/projects/smartroom/smartRoom-wires-8.png"

//proto videos
import SM22 from "../../assets/video/smartroom/smartRoom-prototyping-RC_h264.mp4"
import SM23 from "../../assets/video/smartroom/smartRoom-prototyping-guide_h264.mp4"

import SM24 from "../../assets/img/projects/smartroom/smartRoom-uiCollab-1.png"
import SM25 from "../../assets/img/projects/smartroom/smartRoom-uiCollab-2.png"
import SM26 from "../../assets/img/projects/smartroom/smartRoom-uiCollab-3.png"
import SM27 from "../../assets/img/projects/smartroom/smartRoom-uiCollab-4.png"

import SM28 from "../../assets/img/projects/smartroom/smartRoom-collab-product.png"

import SM29 from "../../assets/img/projects/smartroom/smartRoom-results-1.png"
import SM30 from "../../assets/img/projects/smartroom/smartRoom-results-2.png"
import SM31 from "../../assets/img/projects/smartroom/smartRoom-results-3.png"
import SM32 from "../../assets/img/projects/smartroom/smartRoom-results-4.png"

// Mission Control Assets

import mcHeroVideo from "../../assets/video/MC/MC-hero-video.mp4"
import MC01 from "../../assets/img/projects/MC/MC-IA-old.png"
import MC02 from "../../assets/img/projects/MC/MC-oldUI.png"
import MC03 from "../../assets/img/projects/MC/MC-shadowing-collage.png"
import MC04 from "../../assets/img/projects/MC/MC-personas.png"
import MC05 from "../../assets/img/projects/MC/MC-whiteboarding.png"
import MC06 from "../../assets/img/projects/MC/MC-audits.png"
import MC07 from "../../assets/img/projects/MC/MC-material.png"
import MC08 from "../../assets/img/projects/MC/MC-IA-new.png"

//wires
import MC09 from "../../assets/img/projects/MC/MC-wires-1.png"
import MC10 from "../../assets/img/projects/MC/MC-wires-2.png"
import MC11 from "../../assets/img/projects/MC/MC-wires-3.png"
import MC12 from "../../assets/img/projects/MC/MC-wires-4.png"
import MC13 from "../../assets/img/projects/MC/MC-wires-5.png"
import MC14 from "../../assets/img/projects/MC/MC-wires-6.png"
import MC15 from "../../assets/img/projects/MC/MC-wires-7.png"
import MC16 from "../../assets/img/projects/MC/MC-wires-8.png"

import MC17 from "../../assets/img/projects/MC/MC-prototyping.png"
import MC18 from "../../assets/img/projects/MC/MC-testing.png"

import MC19 from "../../assets/img/projects/MC/MC-wizardLogic-1.png"
import MC20 from "../../assets/img/projects/MC/MC-wizardLogic-2.png"
import MC21 from "../../assets/img/projects/MC/MC-wizardLogic-3.png"

import MC22 from "../../assets/img/projects/MC/MC-spec.png"

import MC23 from "../../assets/img/projects/MC/MC-newUI-1.png"
import MC24 from "../../assets/img/projects/MC/MC-newUI-2.png"
import MC25 from "../../assets/img/projects/MC/MC-newUI-3.png"
import MC26 from "../../assets/img/projects/MC/MC-newUI-4.png"
import MC27 from "../../assets/img/projects/MC/MC-newUI-5.png"
//import MC01 from "../../assets/img/projects/MC/.png"
//import MC01 from "../../assets/img/projects/MC/.png"

//Alcon Lab

import alconHeroVideo from "../../assets/video/alcon/alcon-hero-video.mp4"
import alconProtoVideo from "../../assets/video/alcon/alcon-ux-prototype.mp4"
import ALCN01 from "../../assets/img/labs/alcon/alcon-vision-01.jpg"
import ALCN02 from "../../assets/img/labs/alcon/alcon-vision-02.jpg"
import ALCN03 from "../../assets/img/labs/alcon/alcon-vision-03.jpg"
import ALCN04 from "../../assets/img/labs/alcon/alcon-vision-04.jpg"
import ALCN05 from "../../assets/img/labs/alcon/alcon-vision-05.jpg"
import ALCN06 from "../../assets/img/labs/alcon/alcon-vision-06.jpg"

//Go Market Lab

import goMrktHero from "../../assets/video/goMrkt/goMrkt-hero-video.mp4"
import MRKT01 from "../../assets/img/labs/goMrkt/goMrkt-wire-1.png"
import MRKT02 from "../../assets/img/labs/goMrkt/goMrkt-wire-2.png"
import MRKT03 from "../../assets/img/labs/goMrkt/goMrkt-wire-3.png"
import MRKT04 from "../../assets/img/labs/goMrkt/goMrkt-wire-4.png"
import MRKT05 from "../../assets/img/labs/goMrkt/goMrkt-UI-slices.png"
import MRKT06 from "../../assets/img/labs/goMrkt/goMrkt-AE.png"

//peopleFund Lab

import peopleFundHero from "../../assets/video/peopleFund/peopleFund-hero-video.mp4"
import PFND01 from "../../assets/img/labs/peopleFund/peopleFund-wire-1.png"
import PFND02 from "../../assets/img/labs/peopleFund/peopleFund-wire-2.png"
import PFND03 from "../../assets/img/labs/peopleFund/peopleFund-wire-3.png"
import PFND04 from "../../assets/img/labs/peopleFund/peopleFund-wire-4.png"
import PFND05 from "../../assets/img/labs/peopleFund/peopleFund-UI-slices.png"
import PFND06 from "../../assets/img/labs/peopleFund/peopleFund-AE.png"
import PFND07 from "../../assets/img/labs/peopleFund/peopleFund-ui-1.png"
import PFND08 from "../../assets/img/labs/peopleFund/peopleFund-ui-2.png"
import PFND09 from "../../assets/img/labs/peopleFund/peopleFund-ui-3.png"
import PFND10 from "../../assets/img/labs/peopleFund/peopleFund-ui-4.png"

import ORIGSERVER01 from "../../assets/img/labs/origamiServer/origamiServer-netRequest.png"
import ORIGSERVER02 from "../../assets/img/labs/origamiServer/origamiServer-logic-1.png"
import ORIGSERVER03 from "../../assets/img/labs/origamiServer/origamiServer-repeatingPulse.png"
import ORIGSERVER04 from "../../assets/img/labs/origamiServer/origamiServer-remote.png"
import ORIGSERVER05 from "../../assets/img/labs/origamiServer/origamiServer-terminal.png"
import ORIGSERVER06 from "../../assets/img/labs/origamiServer/origamiServer-macApp.png"
import ORIGSERVER07 from "../../assets/img/labs/origamiServer/origamiServer-inputModule.png"
import OrigamiButtonAnims from "../../assets/video/origamiServer/origamiServer-buttonAnims.mp4"
import OrigamiHero from "../../assets/video/origamiServer/origamiServer-hero.mp4"
import OrigamiDemo from "../../assets/video/origamiServer/origamiServer-demo.mp4"

import portfolioHeroVideo from "../../assets/video/DBDesign/makingOf-hero.mp4"
import MKOF01 from "../../assets/img/labs/makingOf/portfolio-competitiveAudit.png"
import MKOF02 from "../../assets/img/labs/makingOf/portfolio-IA.png"
import MKOF03 from "../../assets/img/labs/makingOf/portfolio-wires-1.png"
import MKOF04 from "../../assets/img/labs/makingOf/portfolio-wires-2.png"
import MKOF05 from "../../assets/img/labs/makingOf/portfolio-wires-3.png"
import MKOF06 from "../../assets/img/labs/makingOf/portfolio-wires-4.png"
import MKOF07 from "../../assets/img/labs/makingOf/portfolio-logo.png"
import MKOF08 from "../../assets/img/labs/makingOf/portfolio-competitors.png"
import MKOF09 from "../../assets/img/labs/makingOf/portfolio-inpiration.png"
import MKOF10 from "../../assets/img/labs/makingOf/portfolio-altUI.png"
import MKOF11 from "../../assets/img/labs/makingOf/portfolio-designSystem.png"
import MKOF12 from "../../assets/img/labs/makingOf/portfolio-code.png"

//UIExplorations
import UIEXHeroVideo from "../../assets/video/UIExplorations/UIExplorations-hero.mp4"
import UIEX01 from "../../assets/img/labs/UIExplorations/dailyUI-A.jpg"
import UIEX02 from "../../assets/img/labs/UIExplorations/dailyUI-B.jpg"
import UIEX03 from "../../assets/img/labs/UIExplorations/dailyUI-C.jpg"
import UIEX04 from "../../assets/img/labs/UIExplorations/dailyUI-D.jpg"
import UIEX05 from "../../assets/img/labs/UIExplorations/dailyUI-E.jpg"

let assets = {
  logo: Logo,
  openGraph,
  icons: {
    tools: {
      airtable,
      drawio,
      dynamodb,
      dynalist,
      git,
      invision,
      lucidchart,
      nodejs,
      origami,
      principle,
      react,
      sketch,
      teamgantt,
      vscode,
      sheets,
      mural,
      cc,
      js,
      socketio,
      css,
      html,
      unity,
      confluence,
      jira,
      slack,
      notion,
      segment,
      framer,
      figma,
      mixpanel,
      logrocket,
    },
    social: {
      github: github,
      medium: medium,
      instagram: instagram,
      linkedin: linkedin,
      dribbble: dribbble,
    },
    nav: {
      nextProject: nextProjectArrow,
      contactClose: contactClose,
      menu: menu,
      closeMenu: closeMenu,
    },
  },

  pages: {
    home: {
      heroPaint: homeBG,
      heroCircle: homeCircle,

      itlistHeroPt1: itlistEditorHomeHero1,
      itlistHeroPt2: itlistEditorHomeHero2,

      DTVGHeroPt1: DTVGHeroPt1,
      DTVGHeroPt2: DTVGHeroPt2,

      SmartRoomMockup: SmartRoomMockup,
      iphoneFaceDown: iphoneFaceDown,
      MCHero: missionControlHomehero,
      MCHeroPt2: missionControlHomehero2,
      alconCard: alconCard,
      goMrktCard: goMrktCard,
      peopleFundCard: peopleFundCard,
      origamiCard: origamiCard,
      portfolioCard: dbDesignCard,
      uiExplorationsHomeCard: dailyUiCard,
      homeSelfie: homeSelfie,
      aboutBrushStroke: aboutBrushStroke,
    },
    about: {
      aboutImage01,
      aboutImage02,
      aboutImage03,
      aboutImage04,
      aboutImage05,
      aboutImage06,
      illustrations: {
        journeyMaps: journeyMaps,
        uxSpecs: uxSpecs,
        researchTesting: researchTesting,
        prototyping: prototyping,
        analytics: analytics,
        accessibility: accessibility,
      },
    },

    labs: {
      alcon: {
        video: {
          hero: alconHeroVideo,
          uxProto: alconProtoVideo,
        },
        ALCN01,
        ALCN02,
        ALCN03,
        ALCN04,
        ALCN05,
        ALCN06,
      },

      goMrkt: {
        heroVideo: goMrktHero,
        MRKT01: MRKT01,
        MRKT02: MRKT02,
        MRKT03: MRKT03,
        MRKT04: MRKT04,
        MRKT05: MRKT05,
        MRKT06: MRKT06,
      },
      peopleFund: {
        heroVideo: peopleFundHero,
        PFND01: PFND01,
        PFND02: PFND02,
        PFND03: PFND03,
        PFND04: PFND04,
        PFND05: PFND05,
        PFND06: PFND06,
        PFND07: PFND07,
        PFND08: PFND08,
        PFND09: PFND09,
        PFND10: PFND10,
      },
      origamiServer: {
        OrigamiButtonAnims: OrigamiButtonAnims,
        OrigamiDemo: OrigamiDemo,
        OrigamiHero: OrigamiHero,
        ORIGSERVER01: ORIGSERVER01,
        ORIGSERVER02: ORIGSERVER02,
        ORIGSERVER03: ORIGSERVER03,
        ORIGSERVER04: ORIGSERVER04,
        ORIGSERVER05: ORIGSERVER05,
        ORIGSERVER06: ORIGSERVER06,
        ORIGSERVER07: ORIGSERVER07,
      },
      makingOf: {
        heroVideo: portfolioHeroVideo,
        MKOF01: MKOF01,
        MKOF02: MKOF02,
        MKOF03: MKOF03,
        MKOF04: MKOF04,
        MKOF05: MKOF05,
        MKOF06: MKOF06,
        MKOF07: MKOF07,
        MKOF08: MKOF08,
        MKOF09: MKOF09,
        MKOF10: MKOF10,
        MKOF11: MKOF11,
        MKOF12: MKOF12,
      },
      UIExplorations: {
        UIEXHeroVideo: UIEXHeroVideo,
        UIEX01: UIEX01,
        UIEX02: UIEX02,
        UIEX03: UIEX03,
        UIEX04: UIEX04,
        UIEX05: UIEX05,
      },
    },

    ILE: {
      video: {
        hero: ILEVideoHero,
        proto: ILEVideoPrototype,
        ui1: ILEVideo01,
        ui2: ILEVideo02,
        ui3: ILEVideo03,
        ui4: ILEVideo04,
        ui5: ILEVideo05,
        ui6: ILEVideo06,
      },
      ILE01,
      ILE02,
      ILE03,
      ILE04,
      ILE05,
      ILE06,
      ILE07,
      ILE08,
      ILE09,
      ILE10,
      ILE11,
      ILE12,
      ILE13,
      ILE14,
      ILE15,
      ILE16,
      ILE17,
      ILE18,
      ILE19,
      ILE20,
      ILE21,
      ILE22,
      ILE23,
      ILE24,
    },

    dtvGuide: {
      video: {
        hero: DTVGVideoHero,
        proto1: DTVGVideo01,
        proto2: DTVGVideo02,
        proto3: DTVGVideo03,
        proto4: DTVGVideo04,
      },
      DTVG01,
      DTVG02,
      DTVG03,
      DTVG04,
      DTVG05,
      DTVG06,
      DTVG07,
      DTVG08,
      DTVG09,
      DTVG10,
      DTVG11,
    },

    smartRoomApp: {
      smartRoomHeroVideo: smartRoomHeroVideo,
      SM01: SM01,
      SM02: SM02,
      SM03: SM03,
      SM04: SM04,
      SM05: SM05,
      SM06: SM06,
      SM07: SM07,
      SM08: SM08,

      SM09: SM09,
      SM10: SM10,
      SM11: SM11,

      SM12: SM12,
      SM13: SM13,

      SM14: SM14,
      SM15: SM15,
      SM16: SM16,
      SM17: SM17,
      SM18: SM18,
      SM19: SM19,
      SM20: SM20,
      SM21: SM21,

      SM22: SM22,
      SM23: SM23,

      SM24: SM24,
      SM25: SM25,
      SM26: SM26,
      SM27: SM27,

      SM28: SM28,

      SM29: SM29,
      SM30: SM30,
      SM31: SM31,
      SM32: SM32,

      // SM:SM,
      // SM:SM,
      // SM:SM,
    },

    MC: {
      heroVideo: mcHeroVideo,
      MC01: MC01,
      MC02: MC02,
      MC03: MC03,
      MC04: MC04,
      MC05: MC05,
      MC06: MC06,
      MC07: MC07,
      MC08: MC08,

      MC09: MC09,
      MC10: MC10,
      MC11: MC11,
      MC12: MC12,
      MC13: MC13,
      MC14: MC14,
      MC15: MC15,
      MC16: MC16,

      MC17: MC17,
      MC18: MC18,

      MC19: MC19,
      MC20: MC20,
      MC21: MC21,
      MC22: MC22,

      MC23: MC23,
      MC24: MC24,
      MC25: MC25,
      MC26: MC26,
      MC27: MC27,
      // MC:MC,
      // MC:MC,
      // MC:MC,
      // MC:MC,
    },
  },

  footer: {
    backgroundLogo: backgroundLogo,
    copy: copyIcon,
    edit: editIcon,
    call: callIcon,
    checkmarkIcon: checkIcon,
  },

  generic: {
    bgStripes: bgStripes,
    blockQuote: blockQuote,
    upNextCard: upNextCard,
  },
}

export default assets
